import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import ReactRotatingText from 'react-rotating-text';
import { Link, StaticQuery, graphql } from 'gatsby';

import Layout from '../components/global/layout/layout';
import ColourBar from '../components/global/colour-bar/colour-bar';
import PodBar from '../components/global/pod-bar/pod-bar';
import Sidebar from '../components/global/sidebar/sidebar';
import EmailSignupForm from '../components/global/email-signup-form/email-signup-form';

// import stickerOldestBible from '../images/sticker-oldest-bible.svg';
// import stickerTour from '../images/sticker-tour.svg';
import stickerCourse from '../images/sticker-course.svg';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query HomepageQuery {
        sanityHomepage {
          title
          content: _rawContent
          tagLines
          videoEmbed
        }
      }
    `}
    render={data => (
      <Layout showImage>
        <div className="relative w-full h-48 text-white uppercase text-xl sm:text-2xl text-right font-semibold">
          <div className="absolute ml-1">
            {/*
            <Link to="/bible-exhibition" className="inline-block">
              <img src={stickerOldestBible} alt="Oldest Bible competition" />
            </Link>
            <Link to="/bible-exhibition" className="inline-block">
              <img src={stickerTour} alt="Book a guided tour" />
            </Link>
            */}
            <Link to="/events" className="inline-block">
              <img src={stickerCourse} alt="Enrol for a short Bible course" />
            </Link>
          </div>
          <span className="absolute bottom-0 right-0 pr-3 pb-2">
            <ReactRotatingText
              pause={5000}
              items={data.sanityHomepage.tagLines}
            />
          </span>
        </div>
        <ColourBar />
        <article className="bg-white text-neutral-darker p-3">
          <div className="md:flex">
            <div className="md:flex-1">
              <h1>{data.sanityHomepage.title}</h1>
              <BlockContent blocks={data.sanityHomepage.content} />
              <div className="bg-belong-light rounded text-sm">
                <h2 className="text-belong px-2">Keep up to date!</h2>
                <div className="px-2">
                  If you would like to keep up to date with these and other
                  Bible events in the Nuneaton & Bedworth area, please subscribe
                  to our occasional newsletter below:
                </div>
                <EmailSignupForm className="mx-0" />
              </div>
            </div>
            {data.sanityHomepage.videoEmbed && (
              <Sidebar videoEmbeds={[data.sanityHomepage.videoEmbed]} />
            )}
          </div>
        </article>
        <PodBar hideBottomColourBar />
      </Layout>
    )}
  />
);

export default IndexPage;

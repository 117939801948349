import * as React from 'react';

export type SidebarProps = {
  images?: { src: string; label: string }[];
  links?: { url: string; label: string }[];
  videoEmbeds?: string[];
};

const Sidebar: React.SFC<SidebarProps> = ({
  images = [],
  videoEmbeds = [],
}) => (
  <aside className="md:ml-3 w-full md:w-auto">
    {images.map(image => (
      <a href={image.src} target="_blank" rel="noopener noreferrer">
        <img
          src={`${image.src}?w=400`}
          alt={image.label}
          key={image.src}
          className="mb-3"
        />
      </a>
    ))}
    {videoEmbeds.map(embed => (
      <div
        className="my-3 embed-responsive aspect-16x9 md:w-32"
        key={`embed-${embed.length}`}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: embed,
        }}
      />
    ))}
  </aside>
);

export default Sidebar;
